import { useQuery } from "@tanstack/react-query";
import { requestUserReport } from "../services/trustApi";

// export const useReportQuery = useQuery({
//   queryKey: ["report"],
//   queryFn: requestUserReport,
// });

export const useReportQuery = (numOfItems) => {
  console.log(numOfItems);
  return useQuery({
    queryKey: ["report", numOfItems],
    queryFn: () => requestUserReport(numOfItems),
    staleTime: 1000, //1000 * 60 * 5,
  });
};
