import axios, { AxiosError } from "axios";
import useLoginStore from "../store/loginStore";

const axiosInstance = axios.create({
  // baseURL: process.env.TRUSTAPI_DEV,
  baseURL: process.env.TRUSTAPI_PRD,
});

axiosInstance.interceptors.request.use((config) => {
  const vtoken = useLoginStore.getState().vtoken;
  console.log("call api with token", vtoken);
  const securedEndpoints = ["/Report/UserEmailReport"];
  if (securedEndpoints.some((url) => config.url.includes(url))) {
    config.headers.Authorization = vtoken ? `Bearer ${vtoken}` : "";
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log("error intercept:", originalRequest);

    // Check for 401 response and ensure we're not retrying again
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // mark that we already retried

      try {
        const ttoken = useLoginStore.getState().ttoken;
        const newTokens = await requestRefresh({ ttoken });
        if (newTokens.isLoggedIn !== true) {
          useLoginStore.getState().logOut();
        }
        // Implement this function
        useLoginStore.getState().logIn(newTokens); // Update the token in the store
        originalRequest.headers["Authorization"] = `Bearer ${newTokens.vtoken}`;

        return axiosInstance(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        // Handle failed refresh here (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    // If the response is not 401 or we already retried, just forward the error
    return Promise.reject(error);
  }
);

export const requestLogin = async (request: object) => {
  try {
    console.log("request for api", request);
    const apiResponse = await axiosInstance.post("/Client/WebClient", request);
    console.log("api request", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

export const requestCodeConfirmation = async (request: object) => {
  try {
    console.log("request for api", request);
    const apiResponse = await axiosInstance.post("/Client/WebCode", request); //, { withCredentials: true });
    console.log("api request", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

export const requestRefresh = async (request: object) => {
  try {
    //console.log("request for api", request);
    const apiResponse = await axiosInstance.post("/Client/Refresh", request);
    console.log("api request", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    console.log("/Client/Refresh", error);
    return error;
  }
};

export const requestUserReport = async (numberOfItems: number) => {
  try {
    console.log("request for api", numberOfItems);
    const apiResponse = await axiosInstance.get("/Report/UserEmailReport", {
      params: { numberOfRequests: numberOfItems },
    });
    console.log("api request", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    console.log("/Report/UserEmailReport", error);
    return error;
  }
};

export const addEmailVerification = async (numberOfItems: number) => {
  try {
    console.log("request for api", numberOfItems);
    const apiResponse = await axiosInstance.post("/Verification/Email", {
      params: { numberOfRequests: numberOfItems },
    });
    console.log("api request", apiResponse.data);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};
