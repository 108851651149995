import React, { useState } from "react";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUserShield, faGear, faSquareArrowUpRight, faSignOut } from "@fortawesome/free-solid-svg-icons";
import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/SettingsPage";
import LoginPage from "../pages/LoginPage";
import VerificationsPage from "../pages/VerificationsPage";
import ConfirmCodePage from "../pages/ConfirmCodePage";
import useLoginStore from "../store/loginStore";

const AppRouter = () => {
  const { isLoggedIn, isCodeSent, logOut } = useLoginStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleLogout = () => {
    logOut();
    closeModal();
  };

  return (
    <div className="h-full">
      <Router>
        <div className="bg-[#0C0129] h-10 flex items-center">
          <div className="w-4/5">
            {isLoggedIn && (
              <nav>
                <ul className="flex">
                  <li>
                    <Link to="/">
                      <FontAwesomeIcon icon={faHome} className="ml-2 mr-2 text-white" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/verifications">
                      <FontAwesomeIcon icon={faUserShield} className="ml-5 mr-2 text-white" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/settings">
                      <FontAwesomeIcon icon={faGear} className="ml-5 mr-2 text-white" />
                    </Link>
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faSignOut}
                      className="ml-5 mr-2 text-white"
                      onClick={openModal} // Open the modal on click
                    />
                  </li>
                </ul>
              </nav>
            )}
          </div>
          <div className="flex w-1/5 item-center justify-end">
            <a
              href="https://tguidweb.onrender.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              <FontAwesomeIcon icon={faSquareArrowUpRight} className="mr-2 text-white" />
              {""}
            </a>
          </div>
        </div>

        {/* Confirmation Modal for Logout */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Confirm Logout"
          style={{
            content: styles.modalContent,
            overlay: styles.overlay,
          }}
        >
          <h2 style={styles.title}>Confirm Logout</h2>
          <div style={styles.divider}></div> {/* Divider Line */}
          <div style={styles.buttonContainer}>
            <button
              onClick={handleLogout}
              style={{ ...styles.button, ...styles.buttonPrimary }}
              onMouseEnter={(e) => ((e.target as HTMLButtonElement).style.backgroundColor = "#c9302c")}
              onMouseLeave={(e) => ((e.target as HTMLButtonElement).style.backgroundColor = "#d9534f")}
            >
              Yes
            </button>
            <button
              onClick={closeModal}
              style={{ ...styles.button, ...styles.buttonSecondary }}
              onMouseEnter={(e) => ((e.target as HTMLButtonElement).style.backgroundColor = "#e0e0e0")}
              onMouseLeave={(e) => ((e.target as HTMLButtonElement).style.backgroundColor = "#f0f0f0")}
            >
              No
            </button>
          </div>
        </Modal>

        <Switch>
          <Route path="/settings">{isLoggedIn ? <SettingsPage /> : <LoginPage />}</Route>
          <Route path="/verifications">{isLoggedIn ? <VerificationsPage /> : <LoginPage />}</Route>
          <Route path="/confirmcode">{isCodeSent && !isLoggedIn ? <ConfirmCodePage /> : <LoginPage />}</Route>
          <Route path="/">{isLoggedIn ? <HomePage /> : <LoginPage />}</Route>
        </Switch>
      </Router>
    </div>
  );
};

export default AppRouter;

// Styles section
const styles = {
  modalContent: {
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "20px",
    width: "300px", // Set modal width to 300px
    height: "150px", // Set modal height to 150px
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    color: "#333",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: "999",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px", // Space between the buttons
    marginTop: "auto",
  },
  button: {
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    padding: "5px 15px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  buttonPrimary: {
    backgroundColor: "#d9534f",
    color: "white",
  },
  buttonSecondary: {
    backgroundColor: "#f0f0f0",
    color: "#333",
  },
  title: {
    marginBottom: "10px",
    fontWeight: "bold",
  },
  divider: {
    height: "1px",
    backgroundColor: "#ddd",
    margin: "10px 0",
  },
};
