import React, { useState, useEffect, CSSProperties } from "react";
import useLoginStore from "../store/loginStore";
import { useMutation } from "@tanstack/react-query";
import { requestLogin } from "../services/trustApi";
import { useHistory } from "react-router-dom";
import { GridLoader } from "react-spinners";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { emailAddress, addEmail, addCodeSent } = useLoginStore();
  const navigate = useHistory();

  const mutation = useMutation({
    mutationFn: requestLogin,
    onSuccess: (data) => {
      console.log(data);
      if (data.codeSent) {
        addCodeSent(data.codeSent);
        navigate.push("/confirmcode");
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    addEmail(email);
    //Handle mutation //TODO add validation
    if (email && password) {
      const credentials = { emailId: email, password: password, webClientType: "outlook" };
      //TODO - for testing uncomment mutate
      //navigate.push("/confirmcode");
      mutation.mutate(credentials);
      console.log("log mutatiom", mutation.isPending);
    } else {
      alert("fix empty");
    }
  };

  // //TODO remove this
  // useEffect(() => {
  //   console.log(`email add is ${emailAddress}`);
  // }, [emailAddress]);

  return (
    <div className="p-4 flex-1">
      {mutation.isPending && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-60 flex justify-center items-center">
          <GridLoader
            color={"#8D6AFF"}
            loading={mutation.isPending}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <section className="flex flex-col items-center justify-center h-screen  mt-[-6rem]">
        <div className="w-full max-w-xs">
          <h2 className="text-center text-2xl font-semibold mb-14 font-kantumruy">
            Use your console credential to sign in
          </h2>
          <form className="bg-white rounded px-8 pt-6 pb-8 mb-4 " onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold font-kantumruy" htmlFor="email-id">
                Email Id
              </label>
              <input
                className="appearance-none border-0 border-b rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email-id"
                type="email"
                //placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold font-kantumruy" htmlFor="password">
                Password
              </label>
              <input
                className="appearance-none border-0 border-b rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder=". . . . . . ."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-[#6C47FF] hover:bg-[#8D6AFF] text-white font-semibold font-kantumruy py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </form>
          <p className="text-center text-gray-600 text-xs">
            New user?{" "}
            <a
              href="https://trustguid.com/"
              className="text-indigo-600 hover:text-indigo-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get started here
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
