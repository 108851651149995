import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

interface loginStore {
  emailAddress: string;
  isCodeSent: boolean;
  ttoken: string;
  vtoken: string;
  isLoggedIn: boolean;
  addEmail: (email: string) => void;
  addCodeSent: (isCodeSent: boolean) => void;
  logIn: (tokens) => void;
  logOut: () => void;
}
//TODO testing value below
const useLoginStore = create<loginStore>()(
  persist(
    (set) => ({
      emailAddress: null, //"",
      isCodeSent: false, //false,
      ttoken: null,
      vtoken: null,
      //"eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJJZCI6IjUiLCJPcmciOiIyIiwiQWNjZXNzIjoiQWRtaW4iLCJuYmYiOjE3MDQ0Mzg5ODksImV4cCI6MTcwNTA0Mzc4OSwiaWF0IjoxNzA0NDM4OTg5fQ.kY1tdUDzw_D-Yr_rmzEOMLYXJeM65_vYfzRpmGSCF7EXzM3_4oUNoKB7KMTfZ0OUljwF6H0DCHqhV5E93jCTlw", //null,
      isLoggedIn: false, //false,
      addEmail: (email) => set(() => ({ emailAddress: email })),
      addCodeSent: (codeSent) => set({ isCodeSent: codeSent }),
      logIn: (tokens) => set({ ttoken: tokens.ttoken, vtoken: tokens.vtoken, isLoggedIn: tokens.isLoggedIn }),
      logOut: () => set({ ttoken: null, vtoken: null, isCodeSent: false, isLoggedIn: false }),
    }),
    {
      name: "tg-user",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

if (process.env.NODE_ENV === "development") mountStoreDevtool("Login Store", useLoginStore);
export default useLoginStore;
