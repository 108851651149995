import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import AppRouter from "../routing/AppRouter";
import ToastNotification from "./ToastNotification";

interface AppProps {
  title: string;
}

const App = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <ToastNotification />
      <div className="flex-grow">
        <AppRouter />
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default App;
