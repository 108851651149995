import React from "react";

const Footer = () => {
  return (
    <div id="footer" className="bg-black h-8">
      <h3>footer</h3>
    </div>
  );
};
export default Footer;
