import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faChevronCircleDown,
  faTrash,
  faHourglassHalf,
  faEnvelopesBulk,
} from "@fortawesome/free-solid-svg-icons";
import useSettingsStore from "../store/settingsStore";

export default function SettingsPage() {
  const { excludeList, addItem, removeItem, setSnooze, setSnoozeTime, snooze } = useSettingsStore();

  const [selectedOption, setSelectedOption] = useState(snooze);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const snoozeTime = calculateSnoozeTime(selectedOption);

    setSelectedOption(selectedOption);
    setSnooze(selectedOption);
    setSnoozeTime(snoozeTime);
  };

  //New approach

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      addItem(inputValue);
      setInputValue("");
    }
  };

  const handleDelete = (itemToDelete) => {
    removeItem(itemToDelete);
  };

  const calculateSnoozeTime = (selectedOption: string): string | null => {
    const now = new Date();

    if (selectedOption.toLowerCase().includes("hour")) {
      const hours = parseInt(selectedOption, 10);
      now.setHours(now.getHours() + hours);
    } else if (selectedOption.toLowerCase().includes("day")) {
      const days = parseInt(selectedOption, 10);
      now.setDate(now.getDate() + days);
    } else if (selectedOption.toLowerCase().includes("week")) {
      const weeks = parseInt(selectedOption, 10);
      now.setDate(now.getDate() + weeks * 7);
    } else if (selectedOption.toLowerCase() === "indefinite") {
      now.setFullYear(now.getFullYear() + 100); // Set snooze time to 100 years in the future
    } else if (selectedOption.toLowerCase() === "none") {
      return null; // No snooze, return null
    } else {
      console.error("Unsupported option selected:", selectedOption);
      return null;
    }

    return now.toISOString(); // Return the snooze time as a date-time string
  };

  return (
    <div className="m-5">
      <h1 className="font-bold text-xl font-kantumruy">Settings</h1>
      <div
        id="snooze-dropdown"
        className="bg-white rounded-lg shadow-lg p-4 flex flex-col mt-8 items-start space-y-4 border border-gray-300"
      >
        <div className="flex items-center space-x-4">
          <div className="flex justify-center items-center">
            <div className={styles.iconBox}>
              <FontAwesomeIcon icon={faHourglassHalf} className={styles.icon} />
            </div>
          </div>
          <div>
            <h4 className="text-lg font-semibold font-kantumruy">Snooze or switch off</h4>
          </div>
        </div>
        <div className="w-full">
          <p className="text-gray-600 mb-2 text-sm font-sans">Enter duration (e.g., 1 hour)</p>
          <div className="inline-block relative w-full">
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="w-full pl-4 pr-10 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm appearance-none hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="" disabled>
                Select duration
              </option>
              <option value="">None</option>
              <option value="1h">1 hour</option>
              <option value="24h">24 hours</option>
              <option value="1wk">1 week</option>
              <option value="Id">Indefinite</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FontAwesomeIcon icon={faChevronCircleDown} size="lg" /> {/* Adjust icon size as needed */}
            </div>
          </div>
        </div>
      </div>
      <div
        id="exclude-email"
        className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-start space-y-4 mt-12 border border-gray-300"
      >
        <div className="flex items-center space-x-4">
          <div className="flex justify-center items-center">
            <div className={styles.iconBox}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck} className={styles.icon} />
            </div>
          </div>
          <div>
            <h4 className="text-lg font-semibold font-kantumruy">Exclude email address</h4>
          </div>
        </div>
        <div className="w-full">
          <p className="text-gray-600 mb-2 text-sm">Enter email address or use pattern (e.g., info@* or user@xyz.*)</p>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Email address or pattern"
            className="w-full pl-4 pr-10 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="w-full">
          <button
            onClick={handleSubmit}
            className="w-full mt-4 bg-indigo-500 text-white py-2 rounded-lg shadow-lg hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
          >
            Submit
          </button>
        </div>
      </div>
      <div
        id="email-list"
        className="bg-white rounded-lg shadow-lg p-4 flex flex-col items-start space-y-4 mt-12 border border-gray-300"
      >
        <div className="flex items-center space-x-4">
          <div className="flex justify-center items-center">
            <div className={styles.iconBox}>
              <FontAwesomeIcon icon={faEnvelopesBulk} className={styles.icon} />
            </div>
          </div>
          <div>
            <h4 className="text-lg font-semibold font-kantumruy">Excluded emails</h4>
          </div>
        </div>
        <div className="w-full">
          <p className="text-gray-600 mb-2 text-sm">Delete record to remove exclusion.</p>
          <table className="w-full">
            <tbody>
              {excludeList.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4 text-sm">{item}</td>
                  <td className="py-2 px-4 text-right">
                    <button onClick={() => handleDelete(item)} className="text-red-500 hover:text-red-700">
                      <FontAwesomeIcon icon={faTrash} className="text-base" /> {""}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const styles = {
  iconBox: `h-10 w-10 flex rounded-full items-center justify-center bg-[#0C0129] 
  ${JSON.stringify({ width: "calc(3rem - 8px)", height: "calc(3rem - 8px)" })}`,
  icon: "text-white",
};
