import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { persist, createJSONStorage } from "zustand/middleware";

interface SettingsStore {
  snooze: string;
  snoozeTime: string;
  template: string;
  excludeList: string[];
  manifestType: string;
  setSnooze: (option) => void;
  setSnoozeTime: (time) => void;
  setManifestType: (option) => void;
  setTemplate: () => void;
  addItem: (item) => void;
  removeItem: (item) => void;
}

const useSettingsStore = create<SettingsStore>()(
  persist(
    (set) => ({
      snooze: null,
      snoozeTime: null,
      template: null,
      excludeList: [],
      manifestType: null,
      setSnooze: (option) => set(() => ({ snooze: option })),
      setSnoozeTime: (time) => set(() => ({ snoozeTime: time })),
      setTemplate: () => set((store) => ({ template: store.template })),
      addItem: (item) => set((state) => ({ excludeList: [...state.excludeList, item] })),
      removeItem: (item) => set((state) => ({ excludeList: state.excludeList.filter((i) => i !== item) })),
      setManifestType: (option) => set(() => ({ manifestType: option })),
    }),
    { name: "tg-settings", storage: createJSONStorage(() => localStorage) }
  )
);

if (process.env.NODE_ENV === "development") mountStoreDevtool("Settings Store", useSettingsStore);
export default useSettingsStore;
