import useLoginStore from "../taskpane/store/loginStore";
import axios from "axios";
import useSettingStore from "../taskpane/store/settingsStore";
import { debugLog } from "../utils/debugLog";
import { debuglog } from "util";

const vtoken = useLoginStore.getState().vtoken;
const axiosInstance = axios.create({
  baseURL: process.env.TRUSTAPI_PRD,
  // baseURL: process.env.TRUSTAPI_DEV,
  headers: { "Content-Type": "application/json", Authorization: `Bearer ${vtoken}` },
});

export const signEmailHeader = async (event: Office.AddinCommands.Event): Promise<void> => {
  // Check if the snooze is still active
  const snoozed = checkIsSnoozed();
  if (snoozed) {
    console.log("Operation is snoozed.");
    return;
  }
  // Check duplicate signature
  const alreadySigned = await isEmailAlreadySigned();
  if (alreadySigned) {
    console.log("Email already signed.");
    return;
  }
  try {
    const { subject, fromEmail, combinedEmails } = await fetchEmailDetails();

    const response = await axiosInstance.post("/Verification/Email", {
      eventId: "566994",
      recipientEmailIds: combinedEmails,
      senderEmailId: fromEmail,
      subject: subject,
      templateId: 1,
    });

    const titleFromResponse = response.data.htmlString;
    const headerHtml = titleFromResponse;

    const asyncResult = await new Promise<Office.AsyncResult<void>>((resolve) => {
      Office.context.mailbox.item.body.prependOnSendAsync(
        headerHtml,
        { coercionType: Office.CoercionType.Html },
        resolve
      );
    });

    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      throw new Error(asyncResult.error.message);
    }

    event.completed({ allowEvent: true });
  } catch (error: any) {
    console.error("Error:", error);
    if (error.response && error.response.status === 401) {
      useLoginStore.getState().logOut();
    }
    event.completed({
      allowEvent: false,
    } as Office.AddinCommands.EventCompletedOptions);
    Office.context.mailbox.item.notificationMessages.addAsync("errorMessage", {
      type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
      message: `An error occurred: ${error.message}`,
    });
  }
};

export const signEmailFooter = async (event: Office.AddinCommands.Event): Promise<void> => {
  // Check if the snooze is still active
  const snoozed = checkIsSnoozed();
  if (snoozed) {
    console.log("Operation is snoozed.");
    return;
  }
  // Check duplicate signature
  const alreadySigned = await isEmailAlreadySigned();
  if (alreadySigned) {
    console.log("Email already signed.");
    return;
  }
  try {
    const { subject, fromEmail, combinedEmails } = await fetchEmailDetails();

    const response = await axiosInstance.post("/Verification/Email", {
      eventId: "566994",
      recipientEmailIds: combinedEmails,
      senderEmailId: fromEmail,
      subject: subject,
      templateId: 1,
    });

    const titleFromResponse = response.data.htmlString;
    const headerHtml = titleFromResponse;

    await appendHTMLEmailBody(headerHtml);
    event.completed({ allowEvent: true });
  } catch (error: any) {
    console.error("Error:", error);
    if (error.response && error.response.status === 401) {
      useLoginStore.getState().logOut();
    }
    event.completed({
      allowEvent: false,
    } as Office.AddinCommands.EventCompletedOptions);
    Office.context.mailbox.item.notificationMessages.addAsync("errorMessage", {
      type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
      message: `An error occurred: ${error.message}`,
    });
  }
};

const appendHTMLEmailBody = async (html: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync("html", (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.error(result.error.message);
        reject(new Error(result.error.message));
        return;
      }
      const currentBody = result.value;
      const newBody = currentBody + `</br>` + html;
      Office.context.mailbox.item.body.setAsync(newBody, { coercionType: Office.CoercionType.Html }, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          console.error(result.error.message);
          reject(new Error(result.error.message));
          return;
        }
        resolve();
      });
    });
  });
};

const fetchEmailDetails = async (): Promise<{ subject: string; fromEmail: string; combinedEmails: string[] }> => {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.from.getAsync((fromResult) => {
      if (fromResult.status === Office.AsyncResultStatus.Failed) {
        reject(new Error(fromResult.error.message));
        return;
      }
      const fromEmail = fromResult.value.emailAddress;

      Office.context.mailbox.item.subject.getAsync((subjectResult) => {
        if (subjectResult.status === Office.AsyncResultStatus.Failed) {
          reject(new Error(subjectResult.error.message));
          return;
        }
        const subject = subjectResult.value;

        Office.context.mailbox.item.to.getAsync((toResult) => {
          if (toResult.status === Office.AsyncResultStatus.Failed) {
            reject(new Error(toResult.error.message));
            return;
          }
          const toEmails = toResult.value.map((recipient) => recipient.emailAddress);

          Office.context.mailbox.item.cc.getAsync((ccResult) => {
            if (ccResult.status === Office.AsyncResultStatus.Failed) {
              reject(new Error(ccResult.error.message));
              return;
            }
            const ccEmails = ccResult.value.map((recipient) => recipient.emailAddress);
            const combinedEmails = [...toEmails, ...ccEmails];

            // Filter out emails that are in the excludeList
            const excludeList = getExcludedEmails();
            const filteredEmails = combinedEmails.filter((email) => !excludeList.includes(email));

            resolve({ subject, fromEmail, combinedEmails: filteredEmails });
          });
        });
      });
    });
  });
};

export const logLocalTime = (): void => {
  const { manifestType, setManifestType } = useSettingStore.getState();
  if (manifestType === null) {
    const localTime = new Date().toLocaleTimeString();
    if (localTime !== null) {
      setManifestType("AdminSource");
      console.log(`Local time: ${localTime}`);
    } else {
      setManifestType("AppSource");
    }
  }
  const x = useSettingStore.getState().manifestType;
  console.log("manifest type is", x);
};

export const appendVerificationFooter = async (): Promise<string> => {
  // Check if the operation is snoozed
  const snoozed = checkIsSnoozed();
  if (snoozed) {
    return "snooze";
  }

  // Check if the email is already signed
  const alreadySigned = await isEmailAlreadySigned();
  if (alreadySigned) {
    console.log("Email already signed.");
    return "duplicate";
  }

  try {
    // Reuse fetchEmailDetails function to get email details
    const { subject, fromEmail, combinedEmails } = await fetchEmailDetails();
    debugLog("filtered list", combinedEmails);
    // Call API to get verification footer
    const response = await axiosInstance.post("/Verification/Email", {
      eventId: "566994",
      recipientEmailIds: combinedEmails,
      senderEmailId: fromEmail,
      subject: subject,
      templateId: 1,
    });

    const footerHtml = response.data.htmlString;

    // Append the footer to the email body
    Office.context.mailbox.item.body.setSelectedDataAsync(
      footerHtml,
      { coercionType: Office.CoercionType.Html },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          throw asyncResult.error.message;
        }
      }
    );
    return "success";
  } catch (error: any) {
    console.error("Error:", error);
    Office.context.mailbox.item.notificationMessages.addAsync("errorMessage", {
      type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
      message: `An error occurred: ${error.message}`,
    });
    return "error";
  }
};

const isEmailAlreadySigned = async (
  signatureText = "This email sender was verified by Trustguid"
): Promise<boolean> => {
  try {
    const emailBody = await new Promise<string>((resolve, reject) => {
      Office.context.mailbox.item.body.getAsync("html", (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(result.error.message);
        }
      });
    });

    // Check if the email body contains the signature text
    return emailBody.includes(signatureText);
  } catch (error) {
    console.error("Failed to check email signature:", error);
    return false; // Assume it's not signed if there's an error
  }
};

const getExcludedEmails = (): string[] => {
  try {
    const settings = localStorage.getItem("tg-settings");

    if (settings) {
      const parsedSettings = JSON.parse(settings);
      const excludedList = parsedSettings.state?.excludeList;
      return excludedList || [];
    }

    return [];
  } catch (error) {
    console.error("Failed to retrieve exclude list from localStorage:", error);
    return [];
  }
};

const checkIsSnoozed = (): boolean => {
  try {
    const settings = localStorage.getItem("tg-settings");

    if (settings) {
      const parsedSettings = JSON.parse(settings);
      const snooze = parsedSettings.state?.snooze;
      debuglog(snooze);

      if (!snooze) {
        return false; // Snooze is null, so return false
      }

      const snoozeTime = new Date(snooze);
      const currentTime = new Date();

      if (currentTime > snoozeTime) {
        return false; // Current time is after snooze time, return false
      }

      return true; // Current time is before snooze time, return true
    }

    return false; // Settings not found, return false
  } catch (error) {
    console.error("Failed to retrieve snooze time from localStorage:", error);
    return false;
  }
};
