import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { requestCodeConfirmation } from "../services/trustApi";
import { useHistory } from "react-router-dom";
import useLoginStore from "../store/loginStore";
import { ClipLoader, GridLoader } from "react-spinners";

export default function ConfirmCodePage() {
  const [code, setCode] = useState("");
  const navigate = useHistory();
  const emailId = useLoginStore((state) => state.emailAddress);
  const { logIn } = useLoginStore();
  console.log(emailId);

  const mutation = useMutation({
    mutationFn: requestCodeConfirmation,
    onSuccess: (data) => {
      console.log("confirmcode mutation", data);
      if (data.isLoggedIn) {
        logIn(data);
        navigate.push("/");
      } else {
        console.log(data.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submit action
    // Process form submission here. For example, you could send a request to your server.
    const webCode = { emailId, code };
    console.log(webCode);
    //TODO to test uncomment mutate
    const x = mutation.mutate(webCode);
    console.log("log mutation code", x);
    //navigate.push("/");
  };

  return (
    <div className="p-4 flex-1">
      {mutation.isPending && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-60 flex justify-center items-center">
          <GridLoader
            color={"#8D6AFF"}
            loading={mutation.isPending}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {mutation.isError && <p>wrong code</p>}
      <section className="flex flex-col items-center justify-center h-screen  mt-[-6rem]">
        <div className="w-full max-w-xs">
          <div className="mb-8 items-center justify-center">
            <h2 className="text-2xl font-semibold  font-kantumruy">Authenticate Your Account</h2>
            <p className="font-kantumruy text-sm text-slate-500 mt-3">
              Keeping you safe is our mission. Please confirm code sent to ..889
            </p>
          </div>
          <form className="bg-white rounded pt-6 pb-8 mb-4 " onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold font-kantumruy" htmlFor="code">
                Enter 6 digit Code
              </label>
              <input
                className="appearance-none border-0 border-b rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="code"
                type="text"
                //placeholder="user@example.com"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-[#6C47FF] hover:bg-[#8D6AFF] text-white font-semibold font-kantumruy py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <p className="text-center text-gray-600 text-xs">
            New user?{" "}
            <a href="https://tguidweb.onrender.com/" className="text-indigo-600 hover:text-indigo-800">
              Get started here
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}

//const styles = StyleSheet.creat
