import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import Header from "./components/Header";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./index.css";

/* global document, Office, module, require */

const rootElement: HTMLElement = document.getElementById("container");
const root = createRoot(rootElement);
const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   retry: 1,
    //   gcTime: 300000,
    //   staleTime: 10000,
    // },
  },
});

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
