import React, { useState } from "react";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useReportQuery } from "../queryHooks/useReportQuery";
import { GridLoader } from "react-spinners";
import useLoginStore from "../store/loginStore";
import { appendVerificationFooter, signEmailFooter } from "../../commands/addVerification";
import { showToast } from "../components/ToastNotification";
import { debugLog } from "../../utils/debugLog";

interface Report {
  emailSent: number;
  emailVerified: number;
  request: [];
}

export default function HomePage() {
  const numOfItems = 5;
  const { data, isLoading } = useReportQuery(numOfItems);
  const [isSigning, setIsSigning] = useState(false);

  const SignEmail = async () => {
    console.log("SignEmail");
    setIsSigning(true); // Start the loading indicator for signing

    try {
      const result = await appendVerificationFooter();
      debugLog("res:", result);
      switch (result) {
        case "success":
          showToast("Email signed successfully!", "success");
          break;
        case "duplicate":
          showToast("Email is already signed.", "info");
          break;
        case "snooze":
          showToast("Operation is snoozed. Check settings.", "warning");
          break;
        case "error":
        default:
          showToast("An error occurred while signing the email.", "error");
          break;
      }
    } catch (error) {
      console.error("Failed to sign the email:", error);
      showToast("An unexpected error occurred.", "error");
    } finally {
      setIsSigning(false); // Stop the loading indicator for signing
    }
  };

  return (
    <div className="m-5">
      {/* Loading indicator for data fetching */}
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-60 flex justify-center items-center">
          <GridLoader
            color={"#8D6AFF"}
            loading={isLoading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {/* Loading indicator for signing the email */}
      {isSigning && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-60 flex justify-center items-center">
          <GridLoader
            color={"#8D6AFF"}
            loading={isSigning}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <div id="message-sent">
        <div className="bg-white rounded-lg shadow-lg p-4 flex items-center border border-gray-300">
          <div className="flex justify-center items-center mr-2">
            <div
              className="rounded-full bg-[#6C47FF] p-1 flex justify-center items-center"
              style={{ width: "calc(3rem - 8px)", height: "calc(3rem - 8px)" }}
            >
              <img src="../../../assets/sms.png" alt="Icon" className="h-6 w-6" />
            </div>
          </div>
          <div className="flex flex-col w-full items-end pl-2">
            <h4 className="text-3xl font-semibold font-kantumruy">{data?.emailSent || 0}</h4>
            <hr className="self-stretch border-t-1 border-gray-200" />
            <p className="text-gray-600 text-sm mt-2 font-sans font-[500]">Emails Signed</p>
          </div>
        </div>
      </div>

      <div id="message-verified" className="mt-8">
        <div className="bg-white rounded-lg shadow-lg p-4 flex items-center border border-gray-300">
          <div className="flex justify-center items-center mr-2">
            <div
              className="rounded-full bg-[#6C47FF] p-1 flex justify-center items-center"
              style={{ width: "calc(3rem - 8px)", height: "calc(3rem - 8px)" }}
            >
              <img src="../../../assets/verify2.png" alt="Icon" className="h-6 w-6" />
            </div>
          </div>
          <div className="flex flex-col w-full items-end pl-2">
            <h4 className="text-3xl font-semibold font-kantumruy">{data?.emailVerified || 0}</h4>
            <hr className="self-stretch border-t-1 border-gray-200" />
            <p className="text-gray-600 text-sm mt-2 font-sans font-[500]">Emails Verified</p>
          </div>
        </div>
      </div>

      <div className="mt-6 text-center">
        <button onClick={SignEmail} className="bg-[#6C47FF] text-white font-bold py-2 px-4 rounded m-2">
          Sign Email
        </button>
      </div>
    </div>
  );
}
