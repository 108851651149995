const __DEV__ = process.env.NODE_ENV !== "production";

export const debugLog = (...args) => {
  if (__DEV__) {
    console.log(...args);
  }
};

export const debugLogApiError = (error) => {
  if (__DEV__) {
    // console.error("Api err response:", error.response?.data);
    // console.error("Api err status:", error.response?.status);
    // console.error("Api err  headers:", error.response?.headers);
    // console.error("Api err  request:", error.request);
    console.error("Api err  message:", error.message);
    // console.error("Api err  config:", error.config);
  }
};
