import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useReportQuery } from "../queryHooks/useReportQuery";
import { GridLoader } from "react-spinners";

export default function VerificationsPage() {
  const numOfItems = 5;
  const { data, isLoading } = useReportQuery(numOfItems);
  return (
    <div className="m-8">
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-60 flex justify-center items-center">
          <GridLoader
            color={"#8D6AFF"}
            loading={isLoading}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <h1 className="text-lg font-bold font-kantumruy">Recent Emails Signed</h1>
      <div>
        {data?.requests?.map((item) => (
          <div id="message-verified" className="mt-8" key={item.sentTo}>
            <div className="bg-white rounded-lg shadow-lg p-2 flex items-center border border-gray-300">
              <div className="flex justify-center items-center mr-2">
                <div
                  className="rounded-full bg-[#EEE6FF] flex justify-center items-center p-1"
                  style={{ width: "calc(3rem - 8px)", height: "calc(3rem - 8px)" }}
                >
                  {/* <img src="../../../assets/verify.png" alt="Icon" className="h-6 w-6" /> */}
                  <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="text-[#6C47FF]" />
                </div>
              </div>
              <div className="flex flex-col w-full items-end pl-2">
                <h4 className="text-[16px] font-normal font-kantumruy">{item.sentTo}</h4>
                <hr className="self-stretch border-t-1 border-gray-200" />
                <p className="text-gray-500 text-sm mt-2 font-sans font-[500]">{item.sentAt}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
